import React from "react";

import { useLocation } from "react-router-dom";

import Image from "../../../../components/elements/various/Image";
import Icon from "../../../../components/elements/various/Icon";

interface PartnerProps {
    title: string;
    image: string;
    link?: string;
}

const Partner = ({ title, image, link }: PartnerProps) => {
    const location = useLocation();

    const getLink = (value: string) => {
        let url = value;

        // Get ID from URL query params
        let params = new URLSearchParams(location?.search);
        let id = params.get('id') || "";

        let project = params.get('project') || "claimroyal";
        id = project + "00000" + id;

        // Replace ID on redirect URL
        url = url.replace('{USER_ID}', id);

        return url;
    }

    return (
        <a target="_blank" className="wall__partner" { ...(link && { href: getLink(link) }) }>
            <div className="wall__partner_content">
                <Image src={image} />
                <h3>{title}</h3>
            </div>
            <div className="wall__partner_hover">
                <Icon name="play-fill" />
            </div>
        </a>
    )
}

export default Partner;