import React from 'react';

interface IconProps {
    name: string;
    size?: number;
    color?: string;
    animation?: string;
    className?: string;
    style?: any;
    onClick?: any;
}

const Icon = ({ name, size = 24, color, animation, className = "", style = {}, onClick }: IconProps) => {
    return (
        <svg className={className}
            {...((onClick) && { "onClick": onClick })}
            style={{
                "width": `${size}px`,
                "height": `${size}px`,
                ...((color) && { "fill": color }),
                ...((animation) && { "animation": animation }),
                ...style
        }}><use href={`/icons.svg#icon-${name}`}></use></svg>
    )
}

export default Icon;