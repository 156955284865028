import React from "react";

interface ImageProps {
    src?: string;
    alt?: string;
    className?: string;
    style?: any;
    event?: {
        text?: string;
        onClick?: any;
    };
    cdn?: boolean;
}

const Image = ({ src, style = { width: "80px", height: "80px" } }: ImageProps) => {
    return (
        <div className="image" style={{ ...style }}>
            <img src={src || "/assets/images/placeholder_square.png"} alt="" />
        </div>
    )
}

export default Image;