import React from "react";

import Icon from "../../../../components/elements/various/Icon";

interface HeaderProps {
    icon?: string;
    title: string;
}

const Header = ({ icon, title }: HeaderProps) => {
    return (
        <div className="wall__header">
            {icon && <Icon name={icon} />}
            <h1 className="wall__header_title">{title}</h1>
        </div>
    )
}

export default Header;