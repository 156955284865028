import React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { Wall } from './views/wall';

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/" element={<Wall />} />
  </>
));

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
