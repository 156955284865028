import React, { useState, useEffect } from "react";

import Slider from "react-slick";

import Header from "./components/Header";
import Partner from "./components/Partner";

const Wall = () => {
    const categories = [{
        header: {
            icon: "joystick",
            title: "Offer Partners"
        },
        methods: [{
            title: "Lootably",
            image: "/assets/images/lootably_logo.png",
            link: "https://wall.lootably.com/?placementID=cldq98zdp00f50110diw0hdly&sid={USER_ID}"
        },{
            title: "Torox",
            image: "/assets/images/torox_logo.png",
            link: "https://torox.io/ifr/show/25075/{USER_ID}/10826"
        },{
            title: "AdGate",
            image: "/assets/images/adgatemedia_logo.png",
            link: "https://wall.adgaterewards.com/oaiWqg/{USER_ID}"
        },{
            title: "BitLabs",
            image: "/assets/images/bitlabs_logo.png",
            link: "https://web.bitlabs.ai/?uid={USER_ID}&token=3fdfa9a0-5047-4ef2-be35-f46ef34bdf71&display_mode=OFFERS"
        },]
    }, {
        header: {
            icon: "ui-checks",
            title: "Survey Partners"
        },
        methods: [{
            title: "CPX Research",
            image: "/assets/images/cpxresearch_logo.png",
            link: "https://offers.cpx-research.com/index.php?app_id=16911&ext_user_id={USER_ID}"
        }, {
            title: "BitLabs",
            image: "/assets/images/bitlabs_logo.png",
            link: "https://web.bitlabs.ai/?uid={USER_ID}&token=3fdfa9a0-5047-4ef2-be35-f46ef34bdf71&display_mode=SURVEYS"
        },]
    }];

    // ---- WINDOW SIZE ----

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    // ---- SLIDER SETTINGS ----

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1340,
            settings: {
                slidesToShow: 7
            }
        }, {
            breakpoint: 1120,
            settings: {
                slidesToShow: 6
            }
        }, {
            breakpoint: 1020,
            settings: {
                slidesToShow: 5
            }
        }, {
            breakpoint: 750,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 620,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 500,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 260,
            settings: {
                slidesToShow: 1
            }
        }]
    };

    // ---- GET SLIDES COUNT ----

    const [slidesCount, setSlidesCount] = useState(settings?.slidesToShow);

    const getSlidesCount = (width: number) => {
        // List of responsive breakpoints
        let responsive = settings.responsive;

        // Filter ones that are higher value that current width
        responsive = responsive.filter(item => item.breakpoint >= width);
        let min = Math.min(...responsive.map(item => item.breakpoint)); // Get min. value
    
        let breakpoint: any = responsive.find(item => item.breakpoint === min); // Get lowest valued breakpoint
        let slides = breakpoint?.settings?.slidesToShow || settings?.slidesToShow;
        
        setSlidesCount(slides);
    }

    useEffect(() => {
        if (windowSize[0]) getSlidesCount(windowSize[0]);
    }, [windowSize]);

    return (
        <div className="wall container">
            {categories.map((category, i) => (
                <div key={i} className="wall__category">
                    <Header { ...category?.header } />
                    <Slider { ...settings } className="wall__category_slider">
                        {[...Array((category?.methods?.length && slidesCount < category?.methods?.length) ? category.methods?.length : slidesCount)].map((_, index) => (
                            (category.methods && category.methods[index]) ? (
                                <Partner key={index} { ...category.methods[index] } />
                            ) : (
                                <div key={index} className="wall__partner placeholder"></div>
                            )
                        ))}
                    </Slider>
                </div>
            ))}
        </div>
    )
}

export { Wall };